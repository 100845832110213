import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  fetchCreditsInfo,
  fetchCompleteUserDetails,
} from '../store/thunks/profile-detail-thunks';
import axios from '../axios';
import parseURL from '../utility/parse-URL';

const Initialize = ({ children }) => {
  const dispatch = useDispatch();

  const {
    push,
    location: { search, pathname },
  } = useHistory();

  const [init, setInit] = useState(false);
  const oneTimeAfterLoginRef = useRef(false);
  const oneTimeAfterLogoutRef = useRef(true);

  const authToken = useSelector((state) => state.auth.authToken);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (authToken) {
      axios.defaults.headers.common['Authorization'] = authToken;

      setInit(true);
      dispatch(fetchCreditsInfo({ fetchNewData: true }));
      dispatch(fetchCompleteUserDetails({}));
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    if (isLoggedIn && !oneTimeAfterLoginRef.current) {
      oneTimeAfterLoginRef.current = true;
      oneTimeAfterLogoutRef.current = false;
      const redirectURL = parseURL(search, 'redirect');

      if (redirectURL) {
        push(`/${redirectURL}`);
      }
    } else if (!isLoggedIn && !oneTimeAfterLogoutRef.current) {
      oneTimeAfterLoginRef.current = false;
      oneTimeAfterLogoutRef.current = true;

      // if (
      //   [
      //     '/dashboard',
      //     '/buy-credits',
      //     '/credit-history',
      //     '/my-bookings',
      //     '/profile',
      //     '/vitals-history',
      //     '/vitals-list',
      //     '/report',
      //     '/checkout',
      //   ]
      //     .map((item) => `/user${item}`)
      //     .includes(pathname)
      // ) {
      //   push('/');
      // }
    }
  }, [isLoggedIn, search, push, pathname]);

  return !isLoggedIn ? children : init ? children : null;
};

export default Initialize;
