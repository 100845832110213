import React, { useState, useLayoutEffect } from 'react';
import { makeStyles, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const snackbarType = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
};

const SnackBarAlert = ({
  message = null,
  type = snackbarType.ERROR,
  duration = 2000,
  closeSnackBar = () => {},
}) => {
  const classes = useStyles();

  const [severityType, setSeverityType] = useState(type);

  const [open, setOpen] = useState(false);

  useLayoutEffect(() => {
    if (message) {
      setSeverityType(type);
      setOpen(true);
    }
  }, [message, type]);

  return (
    <Snackbar
      TransitionProps={{
        onExited: () => {
          setSeverityType(snackbarType.ERROR);
        },
      }}
      open={open}
      autoHideDuration={duration}
      onClose={() => {
        setOpen(false);
        closeSnackBar();
      }}
      classes={{
        root: open ? '' : classes.SnackBarRoot,
      }}
    >
      <Alert
        onClose={() => {
          setOpen(false);
          closeSnackBar();
        }}
        severity={severityType}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarAlert;

const useStyles = makeStyles(
  () => ({
    SnackBarRoot: {
      zIndex: -9999,
    },
  }),
  [],
);
